import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import Fade from "react-reveal/Fade";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import Layout from "../../components/Common/Layout";
import CompanyLogo from "../../components/Website/TrustUs/CompanyLogo";
import ProjectsNavigation from "../../components/Website/ProjectsNavigation";
import ServicePellet from "../../components/Website/ServicePellet";
import ProjectImagesSwiper from "../../components/Website/ProjectImagesSwiper";

const Medicamentos = ({ data }) => {
  const [modalVisible, setModalVisible] = useState(false);

  const imagesData = data.allFile.edges.map(
    (edge) => edge.node.childImageSharp.fluid
  );

  return (
    <Layout
      modalVisible={modalVisible}
      setModalVisible={setModalVisible}
      selectedLanguage="en"
      title="Brocode Studio || Medicamentos à porta"
      description="Medicamentos à porta"
    >
      <div className="project-page">
        <div className="brocode-content">
          <Row className="bk-about-area">
            <Col md={5} xs={12}>
              <Fade bottom duration={2000}>
                <div className="content">
                  <div className="title wow move-up mb--10">
                    <h2>Medicamentos à porta</h2>
                  </div>
                  <ServicePellet service="UI & UX" />
                  <ServicePellet service="Development" />
                  <p class="wow move-up mb--20 d-md-block d-none">
                    Webplatform for medicines home delivery.
                  </p>
                </div>
              </Fade>
            </Col>
            <Col md={7} xs={12}>
              <Img fluid={imagesData[6]} />
            </Col>
          </Row>
          <Fade bottom duration={2000}>
            <Row className="my-md-4 my-1 d-md-none d-block mx-0">
              <p class="wow move-up mb--20">
                Plataforma de encomenda de medicamentos e entrega ao domicilio.
              </p>
            </Row>
          </Fade>
          <Fade bottom duration={2000}>
            <Row className="my-4">
              <Col md={6} xs={12}>
                <Img fluid={imagesData[0]} />
              </Col>
              <Col md={6} xs={12} className="d-none d-md-block">
                <Img fluid={imagesData[1]} />
              </Col>
            </Row>
          </Fade>
          <Fade bottom duration={2000}>
            <Row className="my-4">
              <Col md={6} xs={12}>
                <Img fluid={imagesData[2]} />
              </Col>
              <Col md={6} xs={12} className="">
                <Row className="my-4 d-none d-md-flex">
                  <Col md={4} xs={12}>
                    <Img fluid={imagesData[3]} />
                  </Col>
                  <Col md={4} xs={12}>
                    <Img fluid={imagesData[4]} />
                  </Col>
                  <Col md={4} xs={12}>
                    <Img fluid={imagesData[5]} />
                  </Col>
                </Row>
                <ProjectImagesSwiper
                  imagesList={[imagesData[3], imagesData[4], imagesData[5]]}
                />
              </Col>
            </Row>
          </Fade>

          <Fade bottom duration={2000}>
            <Row className="justify-content-center">
              <CompanyLogo name="medicamentos" />
            </Row>
          </Fade>

          <ProjectsNavigation
            nextProject={{ name: "Liberpater", link: "/work/liberpater" }}
            prevProject={{ name: "Health Coach", link: "/work/healthcoach" }}
            selectedLanguage="en"
          />
        </div>
      </div>
    </Layout>
  );
};

export default Medicamentos;

export const MedicamentosQuery = graphql`
  query MedicamentosPhotos {
    allFile(
      filter: {
        extension: { regex: "/(jpg)|(jpeg)|(png)/" }
        dir: { regex: "/static/img/projects/medicamentos/" }
      }
      sort: { fields: name }
    ) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 1400, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;
